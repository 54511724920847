<template>
    <div class="flex input_box2">
        <div class="title_box">
            <p class="title"><span></span>上传文档</p>
        </div>
        <div class="upload_box">
            <!-- @on-remove="remove_file" -->
            <el-upload accept=".docx" :show-file-list="true" :on-success="handleAvatarSuccess" @on-error="err"
                :limit="1" class="upload-demo" :file-list="upload_file_list" :action='uploadAPI' :data="formData"
                :headers="headers" :multiple="false" :on-exceed="handleExceed" :before-upload="beforeAvatarUpload">
                <div class="el-upload__text">
                    <!-- <p class="text">将文件拖拽到此区域上传</p> -->
                    <img src="@/assets/wordOp/upload.png" alt="" style="margin: 14px  0" />
                    <div class="el-upload__tip" slot="tip">
                        仅支持docx，单文件上传！
                    </div>
                    <div class="el-upload__tip" slot="tip" style="padding-bottom: 10px;">
                        文件大小不可超过10MB!
                    </div>
                    <div class="el-upload__tip" slot="tip" style="padding-bottom: 10px;color: rgba(255, 0, 0, 1);">
                        因文档格式多样，上传word文档后，请仔细检查图片和内容是否有缺失！
                    </div>
                </div>
                <!-- <i class="el-icon-upload"></i> -->
                <div class=""></div>
                <!-- <em>点击上传</em> -->
            </el-upload>
        </div>
    </div>

</template>

<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState(['FillForm', 'placard'])
    },
    mounted() {
        this.headers = {
            'token': this.$user_info.token
        }

    },
    props: {
        up_load_url: {
            type: String,
            default: ''
        },
        up_load_name: {
            type: String,
        }
    },
    watch: {
        up_load_url: {
            handler(newVal) {
                if (newVal) {
                    this.upload_file_list.push({
                        url: newVal.url,
                        name: this.up_load_name,
                    })
                }
                console.log(this.upload_file_list);
            },
            immediate: true
        },

    },
    data() {
        return {
            headers: {},
            uploadAPI: `/api/common/upload`,
            formData: {
                type: 'read'
            },
            upload_file_list: [],
        }
    },
    methods: {
        handleAvatarSuccess(res, file, fileList) {
            console.log(res, file, fileList);
            if (res.code) {
                this.$emit('upload_success', {
                    content: res.data.content,
                    name: file.name
                })
            }
        },
        beforeAvatarUpload(file) {
            let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
            const extension = testmsg === "docx"
            if (!extension) {
                this.$message.error('上传压缩包类型只能是docx!')
            }
            const is10M = file.size / 1024 / 1024 < 10;
            //限制文件上传大小
            if (!is10M) {
                this.$message.error("上传文件大小不能超过 10MB!");
                return false
            }

            return extension
        },
        handleExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择1个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
                } 个文件`
            )
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`)
        },
        // remove_file(file, fileList) {
        //     // 清除上传数据
        //     this.$store.state.FillForm.content = ''
        //     this.$store.state.FillForm.file = ''
        //     this.$store.state.placard.fill_form.content = ''
        //     this.$store.state.FillForm.fill_form.file = ''
        // },
        err(err, file, fileList) {
            console.log(err, file, fileList)
        },

    },
}
</script>

<style lang=scss scoped>
.upload_box {
    width: 50%;
}

// 上传
::v-deep .upload-demo {
    background: #fff;
    border-radius: 0.42rem;

    .el-upload-dragger {
        width: 100%;
        border: 0;
        height: auto;
        padding: 2rem 1rem;
        box-sizing: border-box;
    }

    .el-upload__text {
        .text {
            font-size: 1.4rem;
            color: rgba(51, 51, 51, 1);
        }
    }

    .el-upload {
        display: block;
    }

    .el-upload__tip {
        margin: 0;
    }

    .el-icon-upload {}
}

.input_box2 {
    align-items: center;
    margin-bottom: 20px;

    .title_box {
        width: 10rem;
        padding-right: 15px;
        box-sizing: border-box;

        .title {
            font-size: 14px;
            // width: 14rem;
            text-align: right;

            span {
                color: #ff0000;
                font-size: 14px;
            }
        }
    }



}
</style>